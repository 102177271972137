import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import RevenueImage from '@/assets/svg/lba-apps/lba-statistics/LBA-Statistik_Umsaetze.svg';
//import OfferImage from '@/assets/svg/lba-apps/lba-statistics/LBA-Statistik_Auftrag.svg'
//import AddressImage from '@/assets/svg/lba-apps/lba-statistics/LBA-Statistik_Adressen.svg'
//import ArticleImage from '@/assets/svg/lba-apps/lba-statistics/LBA-Statistik_Artikel.svg'
import MetricImage from '@/assets/svg/lba-apps/lba-statistics/LBA-Statistik_Kennzahlen.svg';
export default {
  components: {
    SiteHeaderComponent
  },
  data() {
    return {
      categories: [{
        name: 'Umsatz',
        image: RevenueImage,
        pathName: 'statisticsRevenue'
      },
      /*
        {
          name: 'Aufträge',
          image: OfferImage,
          pathName: 'statisticsOffer'
        },
        {
          name: 'Kunden',
          image: AddressImage,
          pathName: 'statisticsCustomer'
        },
        {
          name: 'Artikel',
          image: ArticleImage,
          pathName: 'statisticsArticle'
        },*/
      {
        name: 'Kennzahlen',
        image: MetricImage,
        pathName: 'statisticsMetric'
      }]
    };
  }
};
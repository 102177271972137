import StatisticsHomepage from '@/components/statistics/StatisticsHomepage.vue';
export default {
  components: {
    StatisticsHomepage
  },
  computed: {
    isStatisticsIndexSite() {
      if (this.$route.name == 'Statistik') return true;
      return false;
    }
  }
};
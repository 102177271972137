import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('SiteHeaderComponent', {
    attrs: {
      "header": "Statistik"
    }
  }), _c(VContainer, {
    staticClass: "d-flex flex-wrap justify-space-around justify-space-between mt-10"
  }, _vm._l(_vm.categories, function (category) {
    return _c(VCard, {
      key: category.name,
      staticClass: "mx-4 my-2 rounded-lg",
      attrs: {
        "to": {
          name: category.pathName
        },
        "width": "300"
      }
    }, [_c(VImg, {
      attrs: {
        "height": "300",
        "src": category.image
      }
    }), _c(VCardTitle, {
      staticClass: "d-flex justify-center"
    }, [_vm._v(" " + _vm._s(category.name) + " ")])], 1);
  }), 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };